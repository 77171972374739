import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	MasterPageFeatureConfigSymbol,
	BrowserWindowSymbol,
	Props,
	BrowserWindow,
	IPropsStore,
	IPageDidMountHandler,
	IPageDidUnmountHandler,
	pageIdSym,
} from '@wix/thunderbolt-symbols'
import { PageAnchorsPageConfig, PageAnchorsSiteConfig, AnchorWithElement, PageConfigAnchor } from './types'
import { name } from './symbols'
import { debounce } from 'lodash'
import fastdom from 'fastdom'
import { getActiveAnchor, updatePageAnchorsObservers } from './pageAnchorsUtils'

const WIX_ADS = 'WIX_ADS'

const SCROLL_DEBOUNCE_WAIT = 100
const RESIZE_DEBOUNCE_WAIT = 100

/**
 * This is your feature.
 * You can get your configuration written in site-assets and viewer-model injected into your feature
 */
const pageAnchorsFactory = (
	pageFeatureConfig: PageAnchorsPageConfig,
	siteFeatureConfig: PageAnchorsSiteConfig,
	window: BrowserWindow,
	propsStore: IPropsStore,
	pageId: string
): IPageDidMountHandler & IPageDidUnmountHandler => {
	const pageAnchorsObservers = pageFeatureConfig.pageAnchorsObservers.concat(siteFeatureConfig.pageAnchorsObservers)
	const activeAnchorObservers = pageFeatureConfig.activeAnchorObservers.concat(
		siteFeatureConfig.activeAnchorObservers
	)

	const anchors: Array<PageConfigAnchor> = pageFeatureConfig.anchors
	let anchorsWithElements: Array<AnchorWithElement>
	let siteOffset = siteFeatureConfig.siteOffset
	const initAnchors = () => {
		// initialize the wix AD height for anchor calculation
		const wixAds = window!.document.getElementById(WIX_ADS)
		if (wixAds) {
			siteOffset += wixAds.offsetHeight
		}
		// TODO - change this reduce to map once @guybs understands why some elements are missing from the DOM. For now we filter them out
		anchorsWithElements = anchors.reduce((acc: Array<AnchorWithElement>, anchor: PageConfigAnchor): Array<
			AnchorWithElement
		> => {
			const element = window!.document.getElementById(anchor.compId)
			if (element) {
				acc.push({
					...anchor,
					element,
					top: typeof anchor.top !== 'undefined' ? anchor.top : element.getBoundingClientRect().top || 0,
				})
			}
			return acc
		}, [])
		sortAnchors()
	}

	const getMeasuredAnchors = (): Array<AnchorWithElement> =>
		anchorsWithElements.map((anchorWithElement: AnchorWithElement) => ({
			...anchorWithElement,
			top: anchorWithElement.element.getBoundingClientRect().top || 0,
		}))

	const setAnchors = (shouldSetPageAnchors: boolean) => {
		const activeAnchor = getActiveAnchor(anchorsWithElements, siteOffset)
		propsStore.update(
			updatePageAnchorsObservers(
				pageAnchorsObservers,
				activeAnchorObservers,
				activeAnchor,
				anchorsWithElements,
				pageId,
				shouldSetPageAnchors
			)
		)
	}
	const sortAnchors = () => anchorsWithElements.sort((anchor1, anchor2) => anchor1.top - anchor2.top)

	const measureAnchors = (reSort: boolean) => {
		anchorsWithElements = getMeasuredAnchors()
		if (reSort) {
			sortAnchors()
		}
	}

	const calculateAnchors = (reSort: boolean) => {
		fastdom.measure(() => {
			measureAnchors(reSort)
		})
		fastdom.mutate(() => {
			setAnchors(false)
		})
	}
	const pageAnchorsScrollListener = debounce(calculateAnchors.bind(null, false), SCROLL_DEBOUNCE_WAIT)
	const pageAnchorsResizeListener = debounce(calculateAnchors.bind(null, true), RESIZE_DEBOUNCE_WAIT)
	return {
		pageDidMount(): void {
			if (pageAnchorsObservers.length || activeAnchorObservers.length) {
				fastdom.measure(() => {
					initAnchors()
				})
				fastdom.mutate(() => {
					setAnchors(true)
				})
				window!.addEventListener('scroll', pageAnchorsScrollListener)
				window!.addEventListener('resize', pageAnchorsResizeListener)
			}
		},
		pageDidUnmount(): void {
			if (pageAnchorsObservers.length || activeAnchorObservers.length) {
				window!.removeEventListener('scroll', pageAnchorsScrollListener)
				window!.removeEventListener('resize', pageAnchorsResizeListener)
			}
		},
	}
}

export const PageAnchors = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		named(MasterPageFeatureConfigSymbol, name),
		BrowserWindowSymbol,
		Props,
		pageIdSym,
	],
	pageAnchorsFactory
)
